<script>
	import Base from '@backend/Base.vue';

	export default {
		name: "SettingSeo",
		extends: Base,
		data() {
			return {
				Name: "SettingSeo",
				filter:{}
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
			this.filter.project = ''
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
		}
	};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row">
								<div class="col-sm-5">
									<h5 class="card-title">{{ObjectName}} List</h5>
								</div>
								<div class="col-sm-3">
									
								</div>
								<div class="col-sm-3">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" class="form-control" placeholder="Search...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-1">
									<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>{{fields.ss_page}}</th>
									<th>{{fields.ss_title}}</th>
									<th>{{fields.ss_keyword}}</th>
									<th class="text-center" width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{v.ss_page}}</td>
									<td>{{v.ss_title}}</td>
									<td>{{v.ss_keyword}}</td>
									<td class="btn-action text-center">
										<router-link class="icon_action" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'"><i
												class="ti-marker-alt"></i></router-link>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="card-body">
						<Pagination class="mb-0 justify-content-end" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
					</div>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-6">
							<BoField name="ss_project">
								<select2 name="ss_project" v-bind="validation('ss_project')" :options="mrProject" :object="['mp_id','mp_title']" v-model="row.ss_project" :disabled="true">
									<option value="">-- Select Project --</option>
								</select2>
							</BoField>
							<BoField name="ss_title" v-model="row.ss_title"></BoField>
							<BoField name="ss_desc">
								<textarea v-model="row.ss_desc" rows="4" class="form-control"></textarea>
							</BoField>
						</div>
						<div class="col-sm-6">
							<BoField name="ss_page" :attr="{readonly:'readonly'}" v-model="row.ss_page"></BoField>				
							<BoField name="ss_keyword">
								<TagsInput name="ss_keyword" v-model="row.ss_keyword"	:attr="validation('ss_keyword')">
								</TagsInput>
							</BoField>
						</div>
						<div class="col-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading btn-rounded">Save</button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>